import { BarLoader } from "react-spinners";
import { css } from "@emotion/react";
import React from "react";

const loaderStyle = css`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
`;

const CenterLoader = () => <BarLoader css={loaderStyle} />;

export default CenterLoader;
