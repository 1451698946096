import "@babel/polyfill";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import CenterLoader from "~app/components/loaders/center";

import "~fontawesome";
import "~app/styles/antd-customization.less";
import "~app/styles/bulma-customization.sass";
import "~leaflet-styles";
import "~leaflet-js";

Sentry.init({
    dsn: process.env.FRONTEND_SENTRY_DSN,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

const App = lazy(() => import("./app/index"));
ReactDOM.render(
    <Suspense fallback={<CenterLoader />}>
        <App />
  </Suspense>,
    document.getElementById("app"),
);
